.user-menu-button {
  --box-shadow: none;
  margin-right: 40px;
}

.AdminLayout {
  --border: 0;
}

.AdminLayout .AdminLayout__header .AdminLayout__toolbar {
  --background: var(--brand-background);
  --min-height: 5rem;
  --border-width: 0;
}

#notification-button {
  position: relative;
  width: 42px;
  top: 1px;
  right: 1px;
  overflow: visible !important;
}

#notifications-badge {
  background-color: red;
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 100%;
}

.notification-link {
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.download-file-link {
  padding-top: 1rem;
}

.whiteTextBtn {
  color: white !important;
}

.notification-active-link {
  cursor: pointer;
}

.notification-link:hover {
  font-weight: 600;
  color: #c1292e;
}

.TopBar__Logout__button {
  font-size: 50px;
}

.content-wrapper {
  position: relative;
  top: 15px;
}

@media (max-width: 425px) {
  .username-label {
    display: none !important;
  }

  .user-menu-button {
    --box-shadow: none;
    margin-right: 10px;
    --padding-end: 7.5px;
    --padding-start: 7.5px;
  }
}

