.SideMenuPerfomance {
  --ion-item-background: transparent;
  max-width: 20% !important;
  color: white;
  text-align: center;
}

.SideMenuPerfomance ion-item {
  --color: white;
  --background-hover: rgba(0, 0, 0, 0.6);
  --background-activated: var(--brand-primary);
  --padding-bottom: 0.2rem;
  --padding-top: 0.2rem;
  --padding-left: 0.5rem;
  --padding-right: 0.5rem;
  --border-radius: 1rem;
  --border-color: transparent;
  text-transform: uppercase;
  text-align: left;
  font-weight: bold;
}

.SideMenuPerfomance ion-item.isActive {
  --ion-item-background: var(--brand-primary);
  --background-hover: var(--brand-primary);
  --box-shadow: 0 12px 20px -10px rgba(var(--brand-primary), 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12),
    0 7px 8px -5px rgba(var(--brand-primary), 0.2);
}

.SideMenuPerfomance ion-list {
  position: relative;
  z-index: 10;
  padding: 0 1.5rem;
}

.SideMenuPerfomance ion-content {
  --background: url(/assets/images/sidemenu-bg.jpg) center center/cover;
}

.SideMenuPerfomance ion-content::after {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0.8;
  z-index: 3;
  position: absolute;
  background: #000;
}

.SideMenuPerfomance .logo {
  margin-top: 10px;
  padding: 15px;
  z-index: 10;
  position: relative;
  max-width: 60%;
}

.SideMenuPerfomance .SideMenuPerfomance__icon {
  margin-right: 1em;
}

@media (max-width: 992px) {
  .SideMenuPerfomance {
    min-width: 270px !important;
    max-width: none !important;
  }
}
